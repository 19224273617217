.statsShell {
    width: 127px;
}

.statRowsShell div {
    display: flex;
    justify-content: space-between;
}

.statRowsShell div:nth-child(even) {
    background: lightgray;
}

.statRowsShell p {
    z-index: 1;
    margin: 0;
    min-height: 19px;
}

.statRowsShell div div, .statRowsShell div p, .statRowsShell div input {
    width: 50%;
    text-align: center;
}

.statRowsShell p:nth-child(2) {
    font-family: 'Vollkorn SC', serif;
    font-weight: bold;
    margin-top: 2px
}
