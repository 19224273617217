.shieldBlockShell p, .shieldBlockShell input {
    z-index: 1;
    margin: 0;
    height: 19px;
}

.shieldBlockShell {
    width: 151px;
    border: 2px #222 solid;
    display: flex;
    flex-direction: column;
}

.shieldnameLocation {
    padding: 4px 2px 0;
    background: lightgrey;
    width: 100%;
    border-radius: 0;
    text-align: left;
    font-size: 12px;
    width: 100%;
    height: 19px;
}

.twinShieldStats div {
    padding: 0;
    width: 50%;
}

.twinShieldStats div p, .twinShieldStats div input {
    width: 50%;
    text-align: center;
}

.twinShieldStats div p:first-child, .twinShieldStats div input:first-child {
    padding-top: 2px;
    font-family: 'Vollkorn SC', serif;
    font-weight: bold;
    text-align: left;
}

.calculatedStatsShellShield {
    display: flex;
    flex-direction: column;
}

.calculatedStatsShellShield .calculatedStats:nth-child(even), .shieldBlockShell>div:nth-child(even) {
    background: lightgray;
}