/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.strikeout {
    text-decoration: line-through;
}

.buttonStrikeThrough {
    text-decoration: line-through;
}

.fa-spinner {
    font-size: 75px;
    color: white;
    animation-name: spin;
    animation-duration: 5000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.spinnerShell {
    width: 75px;
    min-height: 100vh;
    margin: 45px auto;
}

.spinner-tiny {
    font-size: 30px;
    color: #2192b8;
}

.downloadingBanner {
    position: absolute;
    z-index: 5;
    background: #b76400;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 25px;
    top: 160px;
    -webkit-box-shadow: 5px 5px 15px 5px #000000;
    box-shadow: 5px 5px 15px 5px #000000;
}

.downloadingBanner h4, .downloadingBanner p {
    background: transparent;
    color: white;
    margin: 0;
    padding: 5px 0;
}

.pageOneMiddle {
    margin-top: 7px;
    display: flex;
    justify-content: space-between;
}

.leftColumn {
    width: 435px;
}

.rightColumn {
    width: 325px;
    margin-top: -28px;
}

.marginTop {
    margin-top: 5px;
}

#pdf {
    width: 816px;
}

.pdfViewStylings {
    margin: 35px auto 0;
}

.pdfBackground {
    background-color: grey;
    padding: 15px 0;
}

.removeButtons {
    display: none;
}

.Buttons {
    position: sticky;
    display: flex;
    bottom: 0px;
    justify-content: space-between;
    align-items: center;
}

.generalNotesButton {
    position: sticky;
    top: 0px;
    bottom: unset;
    border-bottom-right-radius: 50px;
}

.generalNotesButton i {
    position: absolute;
    top: -4px;
    left: 5px;
}

.sidebar {
    transform: translate3d(-280px, 0px, 0px);
    position: absolute;
    width: 280px;
    background: #263249;
    color: #eee;
    left: 0;
    height: 100%;
    transition: all .3s;
}

.open {
    transform: translate3d(280px, 0, 0);
}

.corner-button {
    background-color: white;
    height: 50px;
    width: 50px;
}

.left-corner-button {
    border-top-right-radius: 50px;
}

.zindexOne {
    z-index: 1;
}

.left-corner-button i {
    position: absolute;
    bottom: 10px;
    left: 5px;
}

.downloadAsPregen {
    position: absolute;
    bottom: -9px;
    left: -9px;
    background-color: white;
    height: 50px;
    width: 50px;
    transition: s;
    border-radius: 25px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.copyCharacter {
    position: absolute;
    bottom: -9px;
    right: -9px;
    background-color: white;
    height: 50px;
    width: 50px;
    transition: s;
    border-radius: 25px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.zindexNegOne {
    z-index: -1;
}

.left-corner-button:hover .downloadAsPregen {
    bottom: 30px;
    left: 30px;
}

.right-corner-button:hover .copyCharacter {
    bottom: 30px;
    right: 30px;
}

.centerIconRight {
    bottom: 0px !important;
    right: 0px !important;
}

.centerIconLeft {
    bottom: 0px !important;
    left: 0px !important;
}

.downloadAsPregen i {
    font-size: 35px;
    color: grey;
    position: absolute;
    top: 7px;
    left: 8px;
}

.downloadAsPregen:hover i {
    color: #2192b8;
    ;
    transition: s;
    cursor: pointer;
}

.bannerTooltipLeft {
    position: absolute;
    width: 0px;
    background: lightgray;
    font-size: 13px;
    left: 32px;
    padding: 2px 5px 2px 10px;
    z-index: -3;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    overflow: hidden;
    transition: s;
}

.bannerTooltipLeft p {
    margin: 0;
    padding-left: 13px;
    width: 162px;
}

.downloadAsPregen:hover .bannerTooltipLeft {
    width: 162px;
}

.bannerTooltipRight {
    position: absolute;
    width: 0px;
    background: lightgray;
    font-size: 13px;
    right: 17px;
    padding: 2px 10px 2px 5px;
    z-index: -3;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    overflow: hidden;
    transition: s;
}

.singleBanner {
    bottom: 5px;
}

.bannerTooltipRight p {
    margin: 0;
    padding-left: 13px;
    width: 162px;
}

.copyCharacter:hover .bannerTooltipRight, .right-corner-button:hover .singleBanner {
    width: 162px;
}

.flexSpaceBetween {
    display: flex;
    justify-content: space-between;
}

.right-corner-button {
    position: relative;
    border-top-left-radius: 50px;
    overflow: hidden;
}

.right-corner-button i {
    position: absolute;
    bottom: 5px;
    right: 5px;
}

.right-corner-button .copyCharacter i {
    bottom: 10px;
    right: 10px;
}

.editButton {
    position: sticky;
    bottom: 10px;
    margin-left: 10px
}

.fa-file-download, .fa-edit, .fa-save, .fa-clone, .fa-book-open {
    font-size: 30px;
    color: gray
}

.fa-file-download {
    bottom: 19px !important;
}

.fa-window-close {
    font-size: 26px;
    color: gray
}

.fa-file-download:hover, .fa-edit:hover, .fa-save:hover, .fa-window-close:hover, .fa-clone:hover, .fa-book-open:hover {
    color: #2192b8;
}

.pageBase {
    height: 1036px;
    width: 796px;
    background-color: white;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    padding: 15px;
}

.pageViewStylings {
    -webkit-box-shadow: 5px 5px 15px 5px #000000;
    box-shadow: 5px 5px 15px 5px #000000;
}

.dr-icon {
    float: left;
    background-size: contain;
    background-repeat: no-repeat;
    width: 15px;
    height: 15px;
}

.armor-dr-icon {
    background-image: url("../../assets/armor.svg");
}

.shield-dr-icon {
    background-image: url("../../assets/shield.svg");
}

.pageTwoMargin {
    margin-top: 15px;
    margin-bottom: 15px;
}

.pageBase p, .pageBase input {
    font-size: 12px;
    margin: 0 0 2px;
    white-space: pre-wrap;
}

input, textarea {
    background: rgba(173, 216, 230, 0.50);
    border: none;
    font-size: 13px;
}

textarea {
    font-size: 11px;
    width: 100%;
    height: 100%;
}

.editing input {
    background: rgba(173, 216, 230, 0.50) !important;
    height: 19px;
}

.viewer input, .viewer textarea {
    background-color: transparent;
}

.viewer input:hover, .viewer textarea:hover {
    background: rgba(173, 216, 230, 0.50);
    transition: .8s
}

.plates p {
    font-family: 'Vollkorn SC', serif;
}

.filledOut p {
    border-bottom: 2px solid;
    padding: 1px 5px;
}

.cultural-strength-input {
    width: 100%;
}


.pageBase h1 {
    background: #990000;
    color: white;
    margin: 0;
    padding-left: 10px;
    font-size: 15px;
}

.pageBase h2 {
    background: #222;
    color: white;
    margin: 0;
    text-align: center;
    font-size: 13px;
    font-family: 'Vollkorn SC', serif;
    height: 19px;
}

.vitalShell div:nth-child(even) {
    background: lightgray;
}

#confDieShell {
    width: 48px;
    position: relative;
}

#confDieShell p {
    position: absolute !important;
}

#confDieShell p:nth-child(2) {
    color: lightgray;
    z-index: 0;
    font-size: 9px;
    top: 2px;
    left: 2px;
}

.statRowsShell div:nth-child(even) #confDieShell p:nth-child(2) {
    color: whitesmoke
}

.strCarryLocation {
    left: 713px;
    top: 488px;
    width: 80px;
    text-align: center;
}

.shownGearCarryLocation {
    left: 621px;
    top: 488px;
    width: 80px;
    text-align: center;
}

.baseCombatStatsShell {
    position: absolute;
    top: 532px;
    left: 187px;
    width: 177px;
}

.baseCombatStatsShell div {
    display: flex;
    justify-content: space-between;
}

.baseCombatStatsShell div p:first-child {
    font-family: 'Vollkorn SC', serif;
    font-weight: bold;
    width: 61px;
    padding: 2px 5px 0;
}

.baseCombatStatsShell div p:last-child {
    width: 116px;
    text-align: center;
}

.baseCombatStatsShell div:nth-child(odd) {
    background: lightgray
}

.baseCombatStatsShell div div {
    position: relative;
}

.baseCombatStatsShell div div p:first-child {
    position: absolute !important;
    width: 116px;
    text-align: center;
    font-weight: normal;
    font-size: 10px;
    padding-top: 3px;
    color: white
}

.baseCombatStatsShell div:nth-child(even) div p:first-child {
    color: lightgray
}

strong {
    font-size: 12px;
    font-family: 'Vollkorn SC', serif;
    font-weight: unset;
    margin-right: 5px;
}

.racial-temp-edit-shell {
    display: flex;
    width: 224px;
    background-color: lightgray;
}

.racial-temp-edit-shell strong {
    margin-top: 4px;
    margin-right: 4px;
}

.secretNotesDiv {
    padding: 1px;
    border: 1px solid grey;
    background: whitesmoke;
    width: 19px;
    height: 19px;
    display: flex;
}

.general-notes-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #990000;
}

.general-notes-header h1 {
    width: 50%;
}

.general-notes-header .general-notes-subtitle p {
    position: unset;
    margin: 0 15px 0 5px;
    color: whitesmoke
}

.general-notes-subtitle {
    display: flex;
}

.favoranointed {
    top: 535px;
    left: 577px;
    text-align: center;
    transform: scale(1.5);
}

.favorminLocation {
    top: 552px;
    left: 546px;
    width: 74px;
    text-align: center;
}

.generalNotesShell {
    position: absolute;
    top: 532px;
    left: 395px;
    width: 403px;
}

.generalNotesShell div .generalNoteStriping div {
    height: 19px;
}

.generalNotesShell div .generalNoteStriping div:nth-child(even) {
    background: lightgray;
}

.generalnotestextArea {
    width: 100%;
    height: 985px;
    overflow: hidden;
    white-space: pre-wrap;
}

.gearColumns {
    display: flex;
    justify-content: space-between;
}

.gearColumn {
    width: 25%;
}


.gearHeaderShell {
    display: flex;
    justify-content: space-between;
    background: #222;
    padding: 0 0 0 5px
}

.gearHeaderShell h2:first-child {
    width: 75%;
    text-align: left;
}

.gearHeaderShell h2:nth-child(2) {
    width: 25%;
}

.carryShell {
    position: relative;
    width: 100%;
    border: 2px solid #222;
    background: lightgray;
    text-align: center;
    height: 19px;
}

.carryBackground {
    position: absolute;
    top: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.carryBackground p {
    font-family: 'Vollkorn SC', serif;
}

.carryBackground p:first-child, .carryBackground p:last-child, .carryForeground p:first-child, .carryForeground p:last-child {
    min-width: 45%;
}

.carryBackground p:last-child {
    color: whitesmoke;
}

.carryForeground {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.carryForeground p:last-child {
    font-family: 'Vollkorn SC', serif;
    font-weight: bold;
    padding: 1px 0;
}

.circle {
    border-radius: 50px/50px;
    border: solid 2px #000;
    width: 33px;
    height: 21px;
    position: absolute;
    z-index: 2;
}

.panickedCircle, .woundCircle {
    width: 24px !important;
    position: absolute;
    z-index: 2;
    color: red
}

.circle-fill {
    background: whitesmoke;
    font-size: 12px;
    border-radius: 14px;
    padding: 0 0 0 5px;
    width: 28px;
}

.skillMods div:nth-child(odd) {
    background: lightgray
}

.skillMods div p:nth-child(2) {
    width: 24px;
    text-align: center;
}

.skillAdept {
    display: flex;
    justify-content: space-between;
    border-top: 2px solid lightgrey;
    height: 19px;
}

.skilladeptLocation {
    width: 120px;
    text-align: center;
}

.advSkillBackgroundShell {
    display: flex;
    justify-content: space-between;
}

.advSkillBackgroundShell div {
    width: 100%;
}

.advSkillBackgroundShell div .stripings {
    position: absolute;
    top: 0;
}

.advSkillBackgroundShell div .stripings div {
    height: 19px;
    width: 50%;
}

.advSkillBackgroundShell div .stripings div:nth-child(even) {
    background: lightgray
}

.stripeDiv {
    display: flex;
    justify-content: space-between;
}

.stripeDiv div:first-child {
    min-width: 191px;
    border-right: 2px solid grey;
}

.stripeDiv:first-child div:first-child {
    border-right: none;
}

.stripeDiv div:nth-child(2) {
    width: 80px;
    border-right: 2px solid #222;
    background: unset !important
}

.advSkillBackgroundShell div:nth-child(2) .stripings .stripeDiv div:nth-child(2) {
    border-right: none;
}

#nativename {
    width: 93px
}

.combatSkillRowShell .stripings div {
    height: 19px;
    width: 55%;
}

.combatStripings .stripeDiv div:first-child {
    width: 175%;
}

.stripeDiv div:first-child {
    width: 50%;
    border-right: 2px solid grey;
}

.stripeDiv:first-child div:first-child {
    border-right: none;
}

.stripeDiv div:nth-child(2) {
    width: 50%;
    border-right: 2px solid #222;
    background: unset !important
}

.combatSkillRowShell .stripings {
    width: 193px;
}

.combatSkillRowShell .stripings .stripeDiv {
    width: 100%;
    height: 20px;
}

.combatSkillRowShell .stripings .stripeDiv div {
    min-width: unset;
    height: 20px;
}

.skillsUntrainedDiv {
    position: absolute;
    left: 83px;
    z-index: 9999;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.editingStressThreshold input, .editingStressThreshold p {
    width: 50%;
}

.editingStressThreshold div:first-child p {
    width: 75%;
}

#not-found {
    text-align: center;
    width: 400px;
}

#not-found p {
    color: whitesmoke;
    font-family: "hamlet";
    letter-spacing: 2px;
    margin: 0;
    font-size: 30px;
    text-shadow: 4px 2px 2px #222
}

#not-found p:first-child {
    font-size: 145px;
}

.fa-rectangle-xmark {
    margin-top: 2px;
    font-size: 16px;
    color: grey;
    transition: s;
}

.fa-rectangle-xmark:hover {
    color: orange;
}

.locked-out {
    color: red
}

.combatStatWeaponBlocks {
    display: flex;
}
.combatStatWeaponWorkspaces {
    width: 616px;
}

@media (max-width: 825px) {
    .pageBase {
        width: 100vw;
    }

    .pdfViewStylings {
        margin: 0;
    }

    .pageViewStylings {
        box-shadow: unset;
    }
}