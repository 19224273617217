.weaponSquares {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: space-between;
}

.weaponsquare {
    width: 151px;
}

.weaponsquare .name {
    height: 20px;
    width: 154px;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    height: 15px;
}

.weaponsquare .attack {
    height: 32px;
    width: 67px;
    text-align: center;
    font-size: 19px;
}

.weaponsquare .recovery {
    height: 30px;
    width: 45px;
    text-align: center;
    font-size: 18px;
}

.weaponsquare .init {
    height: 32px;
    width: 54px;
    text-align: center;
    font-size: 19px;
}

.weaponsquare .def {
    z-index: 1;
    font-size: 17px;
    height: 53px;
    width: 47px;
    text-align: center;
    margin: 0 !important;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: space-evenly;
}

.weaponsquare .encumb {
    height: 24px;
    width: 38px;
    text-align: center;
    font-size: 18px;
}

.weaponsquare .drshell {
    display: flex;
    flex-direction: column;
    height: 53px;
    width: 47px;
    text-align: center;
    justify-content: center;
}

.weaponsquare .measure {
    height: 30px;
    width: 45px;
    text-align: center;
    font-size: 18px;
}

.weaponsquare .damage {
    height: 45px;
}

.weaponsquare .parry {
    height: 36px;
    width: 49px;
    text-align: center;
    font-size: 18px;
}

.weaponsquare .squareTitle {
    font-size: 10px;
    font-family: 'Vollkorn SC', serif;
    height: 20px;
    width: 155px;
    text-align: left;
}

.combat-table-shell {
    font-size: 10px;
    height: 151px;
    width: 158px;
    display: flex;
}

.combat-table-column {
    width: 49%;
}

.combat-table-shell .combat-table-column:nth-child(2) {
    border-left: 1px solid;
}

.combat-table-shell .combat-table-column div:nth-child(odd) {
    background-color: lightgray;
}

.combat-table-column div:first-child {
    font-family: "hamlet";
    letter-spacing: 1.5px;
    font-size: 11px;
    background-color: #222 !important;
    color: whitesmoke;
    width: 100%;
}

.combat-table-column div {
    display: flex;
    justify-content: space-between;
}

.combat-table-column div p {
    padding: 0 5px;
}

.combat-table-column div p {
    font-size: 10px;
    margin: 0;
}

.first-cell {
    width: 35%;
    font-weight: bold;
    font-family: 'Vollkorn SC', serif;
    font-size: 11px;
}

.combat-table-column .column {
    flex-direction: column;
}

.combat-table-column .column p {
    width: 100%;
    text-align: left;
    height: 15px;
}

.combat-table-column .column .double {
    position: relative;
    height: 45px;
    width: 66.41px;
}

.damage .tooltip {
    position: absolute;
    left: -117px;
    z-index: -111;
    background: rgba(173, 216, 230);
    padding: 5px;
    width: 105px;
    border-radius: 5px;
    opacity: 0;
    transition: .6s;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.drshell:hover .tooltip, .damage:hover .tooltip {
    opacity: 1;
    transition-delay: 1s;
    z-index: 9999;
}

#armorDr, #def {
    margin: 0;
    font-size: 13px;
}

#shieldDr, #shieldCover {
    border-top: 1px solid lightgrey;
    margin: 0;
    font-size: 10px;
}

.fakebutton {
    position: relative;
}

.fakebutton:hover {
    background: rgba(173, 216, 230, 0.50);
    transition: .8s;
    cursor: pointer;
    border-radius: 5px;
}

.fakebutton:active {
    background: rgba(173, 216, 230, 0.75);
    transition: 0s;
}