.info-shell {
    display: flex;
}

.info-row {
    display: flex;
    align-items: center;
}

.title-info-pair {
    display: flex;
    align-items: center;
}

.ultra-strong {
    font-weight: 1000;
}

.nameLocation {
    border-bottom: 2px solid;
    width: 272px;
    padding: 1px 5px;
}

.playerLocation {
    border-bottom: 2px solid;
    width: 123px;
}

.raceLocation {
    border-bottom: 2px solid;
    width: 126px;
    padding: 1px 5px;
}

.levelLocation {
    border-bottom: 2px solid;
    width: 28px;
    text-align: center;
}

.primaryLocation {
    width: 100px;
    padding-left: 5px;
    border-bottom: 2px solid;
}

.secondaryLocation {
    width: 100px;
    border-bottom: 2px solid;
    padding-left: 5px;
}

.crpLocation {
    width: 53px;
    text-align: center;
    border-bottom: 2px solid;
}

.excurrentLocation {
    text-align: center;
    width: 53px;
    border-bottom: 2px solid;
}

.extolevelLocation {
    width: 53px;
    text-align: center;
    border-bottom: 2px solid;
}

.bonfireLogo {
    background-image: url("../../../../../../assets/logo.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 213px;
}