@import url(https://fonts.googleapis.com/css2?family=Vollkorn+SC&display=swap);
@font-face {
  font-family: 'ancient';
  src: url(/static/media/jancient.8e6f9ebb.ttf) format("truetype");
}
@font-face {
  font-family: 'hamlet';
  src: url(/static/media/Hamletornot.7ca7daca.ttf) format("truetype");
}

body {
  margin: 0;
  background-color: gray;
  background-image: url(/static/media/background.dc1b5bea.png);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  display: flex;
  align-items: center;
}

h1, h2, h3, h4, h5 {
  font-family: "hamlet";
  letter-spacing: 1px;
}

h1 {
  font-size: 25px;
}

a:hover h1, a:hover p {
  color: lightgray;
}

.beta {
  margin-left: 10px
}

.displayNone {
  display: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.strikeout {
    text-decoration: line-through;
}

.buttonStrikeThrough {
    text-decoration: line-through;
}

.fa-spinner {
    font-size: 75px;
    color: white;
    animation-name: spin;
    animation-duration: 5000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.spinnerShell {
    width: 75px;
    min-height: 100vh;
    margin: 45px auto;
}

.spinner-tiny {
    font-size: 30px;
    color: #2192b8;
}

.downloadingBanner {
    position: absolute;
    z-index: 5;
    background: #b76400;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 25px;
    top: 160px;
    box-shadow: 5px 5px 15px 5px #000000;
}

.downloadingBanner h4, .downloadingBanner p {
    background: transparent;
    color: white;
    margin: 0;
    padding: 5px 0;
}

.pageOneMiddle {
    margin-top: 7px;
    display: flex;
    justify-content: space-between;
}

.leftColumn {
    width: 435px;
}

.rightColumn {
    width: 325px;
    margin-top: -28px;
}

.marginTop {
    margin-top: 5px;
}

#pdf {
    width: 816px;
}

.pdfViewStylings {
    margin: 35px auto 0;
}

.pdfBackground {
    background-color: grey;
    padding: 15px 0;
}

.removeButtons {
    display: none;
}

.Buttons {
    position: -webkit-sticky;
    position: sticky;
    display: flex;
    bottom: 0px;
    justify-content: space-between;
    align-items: center;
}

.generalNotesButton {
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    bottom: unset;
    border-bottom-right-radius: 50px;
}

.generalNotesButton i {
    position: absolute;
    top: -4px;
    left: 5px;
}

.sidebar {
    transform: translate3d(-280px, 0px, 0px);
    position: absolute;
    width: 280px;
    background: #263249;
    color: #eee;
    left: 0;
    height: 100%;
    transition: all .3s;
}

.open {
    transform: translate3d(280px, 0, 0);
}

.corner-button {
    background-color: white;
    height: 50px;
    width: 50px;
}

.left-corner-button {
    border-top-right-radius: 50px;
}

.zindexOne {
    z-index: 1;
}

.left-corner-button i {
    position: absolute;
    bottom: 10px;
    left: 5px;
}

.downloadAsPregen {
    position: absolute;
    bottom: -9px;
    left: -9px;
    background-color: white;
    height: 50px;
    width: 50px;
    transition: s;
    border-radius: 25px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.copyCharacter {
    position: absolute;
    bottom: -9px;
    right: -9px;
    background-color: white;
    height: 50px;
    width: 50px;
    transition: s;
    border-radius: 25px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.zindexNegOne {
    z-index: -1;
}

.left-corner-button:hover .downloadAsPregen {
    bottom: 30px;
    left: 30px;
}

.right-corner-button:hover .copyCharacter {
    bottom: 30px;
    right: 30px;
}

.centerIconRight {
    bottom: 0px !important;
    right: 0px !important;
}

.centerIconLeft {
    bottom: 0px !important;
    left: 0px !important;
}

.downloadAsPregen i {
    font-size: 35px;
    color: grey;
    position: absolute;
    top: 7px;
    left: 8px;
}

.downloadAsPregen:hover i {
    color: #2192b8;
    ;
    transition: s;
    cursor: pointer;
}

.bannerTooltipLeft {
    position: absolute;
    width: 0px;
    background: lightgray;
    font-size: 13px;
    left: 32px;
    padding: 2px 5px 2px 10px;
    z-index: -3;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    overflow: hidden;
    transition: s;
}

.bannerTooltipLeft p {
    margin: 0;
    padding-left: 13px;
    width: 162px;
}

.downloadAsPregen:hover .bannerTooltipLeft {
    width: 162px;
}

.bannerTooltipRight {
    position: absolute;
    width: 0px;
    background: lightgray;
    font-size: 13px;
    right: 17px;
    padding: 2px 10px 2px 5px;
    z-index: -3;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    overflow: hidden;
    transition: s;
}

.singleBanner {
    bottom: 5px;
}

.bannerTooltipRight p {
    margin: 0;
    padding-left: 13px;
    width: 162px;
}

.copyCharacter:hover .bannerTooltipRight, .right-corner-button:hover .singleBanner {
    width: 162px;
}

.flexSpaceBetween {
    display: flex;
    justify-content: space-between;
}

.right-corner-button {
    position: relative;
    border-top-left-radius: 50px;
    overflow: hidden;
}

.right-corner-button i {
    position: absolute;
    bottom: 5px;
    right: 5px;
}

.right-corner-button .copyCharacter i {
    bottom: 10px;
    right: 10px;
}

.editButton {
    position: -webkit-sticky;
    position: sticky;
    bottom: 10px;
    margin-left: 10px
}

.fa-file-download, .fa-edit, .fa-save, .fa-clone, .fa-book-open {
    font-size: 30px;
    color: gray
}

.fa-file-download {
    bottom: 19px !important;
}

.fa-window-close {
    font-size: 26px;
    color: gray
}

.fa-file-download:hover, .fa-edit:hover, .fa-save:hover, .fa-window-close:hover, .fa-clone:hover, .fa-book-open:hover {
    color: #2192b8;
}

.pageBase {
    height: 1036px;
    width: 796px;
    background-color: white;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    padding: 15px;
}

.pageViewStylings {
    box-shadow: 5px 5px 15px 5px #000000;
}

.dr-icon {
    float: left;
    background-size: contain;
    background-repeat: no-repeat;
    width: 15px;
    height: 15px;
}

.armor-dr-icon {
    background-image: url(/static/media/armor.e158e989.svg);
}

.shield-dr-icon {
    background-image: url(/static/media/shield.c1b08483.svg);
}

.pageTwoMargin {
    margin-top: 15px;
    margin-bottom: 15px;
}

.pageBase p, .pageBase input {
    font-size: 12px;
    margin: 0 0 2px;
    white-space: pre-wrap;
}

input, textarea {
    background: rgba(173, 216, 230, 0.50);
    border: none;
    font-size: 13px;
}

textarea {
    font-size: 11px;
    width: 100%;
    height: 100%;
}

.editing input {
    background: rgba(173, 216, 230, 0.50) !important;
    height: 19px;
}

.viewer input, .viewer textarea {
    background-color: transparent;
}

.viewer input:hover, .viewer textarea:hover {
    background: rgba(173, 216, 230, 0.50);
    transition: .8s
}

.plates p {
    font-family: 'Vollkorn SC', serif;
}

.filledOut p {
    border-bottom: 2px solid;
    padding: 1px 5px;
}

.cultural-strength-input {
    width: 100%;
}


.pageBase h1 {
    background: #990000;
    color: white;
    margin: 0;
    padding-left: 10px;
    font-size: 15px;
}

.pageBase h2 {
    background: #222;
    color: white;
    margin: 0;
    text-align: center;
    font-size: 13px;
    font-family: 'Vollkorn SC', serif;
    height: 19px;
}

.vitalShell div:nth-child(even) {
    background: lightgray;
}

#confDieShell {
    width: 48px;
    position: relative;
}

#confDieShell p {
    position: absolute !important;
}

#confDieShell p:nth-child(2) {
    color: lightgray;
    z-index: 0;
    font-size: 9px;
    top: 2px;
    left: 2px;
}

.statRowsShell div:nth-child(even) #confDieShell p:nth-child(2) {
    color: whitesmoke
}

.strCarryLocation {
    left: 713px;
    top: 488px;
    width: 80px;
    text-align: center;
}

.shownGearCarryLocation {
    left: 621px;
    top: 488px;
    width: 80px;
    text-align: center;
}

.baseCombatStatsShell {
    position: absolute;
    top: 532px;
    left: 187px;
    width: 177px;
}

.baseCombatStatsShell div {
    display: flex;
    justify-content: space-between;
}

.baseCombatStatsShell div p:first-child {
    font-family: 'Vollkorn SC', serif;
    font-weight: bold;
    width: 61px;
    padding: 2px 5px 0;
}

.baseCombatStatsShell div p:last-child {
    width: 116px;
    text-align: center;
}

.baseCombatStatsShell div:nth-child(odd) {
    background: lightgray
}

.baseCombatStatsShell div div {
    position: relative;
}

.baseCombatStatsShell div div p:first-child {
    position: absolute !important;
    width: 116px;
    text-align: center;
    font-weight: normal;
    font-size: 10px;
    padding-top: 3px;
    color: white
}

.baseCombatStatsShell div:nth-child(even) div p:first-child {
    color: lightgray
}

strong {
    font-size: 12px;
    font-family: 'Vollkorn SC', serif;
    font-weight: unset;
    margin-right: 5px;
}

.racial-temp-edit-shell {
    display: flex;
    width: 224px;
    background-color: lightgray;
}

.racial-temp-edit-shell strong {
    margin-top: 4px;
    margin-right: 4px;
}

.secretNotesDiv {
    padding: 1px;
    border: 1px solid grey;
    background: whitesmoke;
    width: 19px;
    height: 19px;
    display: flex;
}

.general-notes-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #990000;
}

.general-notes-header h1 {
    width: 50%;
}

.general-notes-header .general-notes-subtitle p {
    position: unset;
    margin: 0 15px 0 5px;
    color: whitesmoke
}

.general-notes-subtitle {
    display: flex;
}

.favoranointed {
    top: 535px;
    left: 577px;
    text-align: center;
    transform: scale(1.5);
}

.favorminLocation {
    top: 552px;
    left: 546px;
    width: 74px;
    text-align: center;
}

.generalNotesShell {
    position: absolute;
    top: 532px;
    left: 395px;
    width: 403px;
}

.generalNotesShell div .generalNoteStriping div {
    height: 19px;
}

.generalNotesShell div .generalNoteStriping div:nth-child(even) {
    background: lightgray;
}

.generalnotestextArea {
    width: 100%;
    height: 985px;
    overflow: hidden;
    white-space: pre-wrap;
}

.gearColumns {
    display: flex;
    justify-content: space-between;
}

.gearColumn {
    width: 25%;
}


.gearHeaderShell {
    display: flex;
    justify-content: space-between;
    background: #222;
    padding: 0 0 0 5px
}

.gearHeaderShell h2:first-child {
    width: 75%;
    text-align: left;
}

.gearHeaderShell h2:nth-child(2) {
    width: 25%;
}

.carryShell {
    position: relative;
    width: 100%;
    border: 2px solid #222;
    background: lightgray;
    text-align: center;
    height: 19px;
}

.carryBackground {
    position: absolute;
    top: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.carryBackground p {
    font-family: 'Vollkorn SC', serif;
}

.carryBackground p:first-child, .carryBackground p:last-child, .carryForeground p:first-child, .carryForeground p:last-child {
    min-width: 45%;
}

.carryBackground p:last-child {
    color: whitesmoke;
}

.carryForeground {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.carryForeground p:last-child {
    font-family: 'Vollkorn SC', serif;
    font-weight: bold;
    padding: 1px 0;
}

.circle {
    border-radius: 50px/50px;
    border: solid 2px #000;
    width: 33px;
    height: 21px;
    position: absolute;
    z-index: 2;
}

.panickedCircle, .woundCircle {
    width: 24px !important;
    position: absolute;
    z-index: 2;
    color: red
}

.circle-fill {
    background: whitesmoke;
    font-size: 12px;
    border-radius: 14px;
    padding: 0 0 0 5px;
    width: 28px;
}

.skillMods div:nth-child(odd) {
    background: lightgray
}

.skillMods div p:nth-child(2) {
    width: 24px;
    text-align: center;
}

.skillAdept {
    display: flex;
    justify-content: space-between;
    border-top: 2px solid lightgrey;
    height: 19px;
}

.skilladeptLocation {
    width: 120px;
    text-align: center;
}

.advSkillBackgroundShell {
    display: flex;
    justify-content: space-between;
}

.advSkillBackgroundShell div {
    width: 100%;
}

.advSkillBackgroundShell div .stripings {
    position: absolute;
    top: 0;
}

.advSkillBackgroundShell div .stripings div {
    height: 19px;
    width: 50%;
}

.advSkillBackgroundShell div .stripings div:nth-child(even) {
    background: lightgray
}

.stripeDiv {
    display: flex;
    justify-content: space-between;
}

.stripeDiv div:first-child {
    min-width: 191px;
    border-right: 2px solid grey;
}

.stripeDiv:first-child div:first-child {
    border-right: none;
}

.stripeDiv div:nth-child(2) {
    width: 80px;
    border-right: 2px solid #222;
    background: unset !important
}

.advSkillBackgroundShell div:nth-child(2) .stripings .stripeDiv div:nth-child(2) {
    border-right: none;
}

#nativename {
    width: 93px
}

.combatSkillRowShell .stripings div {
    height: 19px;
    width: 55%;
}

.combatStripings .stripeDiv div:first-child {
    width: 175%;
}

.stripeDiv div:first-child {
    width: 50%;
    border-right: 2px solid grey;
}

.stripeDiv:first-child div:first-child {
    border-right: none;
}

.stripeDiv div:nth-child(2) {
    width: 50%;
    border-right: 2px solid #222;
    background: unset !important
}

.combatSkillRowShell .stripings {
    width: 193px;
}

.combatSkillRowShell .stripings .stripeDiv {
    width: 100%;
    height: 20px;
}

.combatSkillRowShell .stripings .stripeDiv div {
    min-width: unset;
    height: 20px;
}

.skillsUntrainedDiv {
    position: absolute;
    left: 83px;
    z-index: 9999;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.editingStressThreshold input, .editingStressThreshold p {
    width: 50%;
}

.editingStressThreshold div:first-child p {
    width: 75%;
}

#not-found {
    text-align: center;
    width: 400px;
}

#not-found p {
    color: whitesmoke;
    font-family: "hamlet";
    letter-spacing: 2px;
    margin: 0;
    font-size: 30px;
    text-shadow: 4px 2px 2px #222
}

#not-found p:first-child {
    font-size: 145px;
}

.fa-rectangle-xmark {
    margin-top: 2px;
    font-size: 16px;
    color: grey;
    transition: s;
}

.fa-rectangle-xmark:hover {
    color: orange;
}

.locked-out {
    color: red
}

.combatStatWeaponBlocks {
    display: flex;
}
.combatStatWeaponWorkspaces {
    width: 616px;
}

@media (max-width: 825px) {
    .pageBase {
        width: 100vw;
    }

    .pdfViewStylings {
        margin: 0;
    }

    .pageViewStylings {
        box-shadow: unset;
    }
}
.weaponSquares {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: space-between;
}

.weaponsquare {
    width: 151px;
}

.weaponsquare .name {
    height: 20px;
    width: 154px;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    height: 15px;
}

.weaponsquare .attack {
    height: 32px;
    width: 67px;
    text-align: center;
    font-size: 19px;
}

.weaponsquare .recovery {
    height: 30px;
    width: 45px;
    text-align: center;
    font-size: 18px;
}

.weaponsquare .init {
    height: 32px;
    width: 54px;
    text-align: center;
    font-size: 19px;
}

.weaponsquare .def {
    z-index: 1;
    font-size: 17px;
    height: 53px;
    width: 47px;
    text-align: center;
    margin: 0 !important;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: space-evenly;
}

.weaponsquare .encumb {
    height: 24px;
    width: 38px;
    text-align: center;
    font-size: 18px;
}

.weaponsquare .drshell {
    display: flex;
    flex-direction: column;
    height: 53px;
    width: 47px;
    text-align: center;
    justify-content: center;
}

.weaponsquare .measure {
    height: 30px;
    width: 45px;
    text-align: center;
    font-size: 18px;
}

.weaponsquare .damage {
    height: 45px;
}

.weaponsquare .parry {
    height: 36px;
    width: 49px;
    text-align: center;
    font-size: 18px;
}

.weaponsquare .squareTitle {
    font-size: 10px;
    font-family: 'Vollkorn SC', serif;
    height: 20px;
    width: 155px;
    text-align: left;
}

.combat-table-shell {
    font-size: 10px;
    height: 151px;
    width: 158px;
    display: flex;
}

.combat-table-column {
    width: 49%;
}

.combat-table-shell .combat-table-column:nth-child(2) {
    border-left: 1px solid;
}

.combat-table-shell .combat-table-column div:nth-child(odd) {
    background-color: lightgray;
}

.combat-table-column div:first-child {
    font-family: "hamlet";
    letter-spacing: 1.5px;
    font-size: 11px;
    background-color: #222 !important;
    color: whitesmoke;
    width: 100%;
}

.combat-table-column div {
    display: flex;
    justify-content: space-between;
}

.combat-table-column div p {
    padding: 0 5px;
}

.combat-table-column div p {
    font-size: 10px;
    margin: 0;
}

.first-cell {
    width: 35%;
    font-weight: bold;
    font-family: 'Vollkorn SC', serif;
    font-size: 11px;
}

.combat-table-column .column {
    flex-direction: column;
}

.combat-table-column .column p {
    width: 100%;
    text-align: left;
    height: 15px;
}

.combat-table-column .column .double {
    position: relative;
    height: 45px;
    width: 66.41px;
}

.damage .tooltip {
    position: absolute;
    left: -117px;
    z-index: -111;
    background: rgba(173, 216, 230);
    padding: 5px;
    width: 105px;
    border-radius: 5px;
    opacity: 0;
    transition: .6s;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.drshell:hover .tooltip, .damage:hover .tooltip {
    opacity: 1;
    transition-delay: 1s;
    z-index: 9999;
}

#armorDr, #def {
    margin: 0;
    font-size: 13px;
}

#shieldDr, #shieldCover {
    border-top: 1px solid lightgrey;
    margin: 0;
    font-size: 10px;
}

.fakebutton {
    position: relative;
}

.fakebutton:hover {
    background: rgba(173, 216, 230, 0.50);
    transition: .8s;
    cursor: pointer;
    border-radius: 5px;
}

.fakebutton:active {
    background: rgba(173, 216, 230, 0.75);
    transition: 0s;
}
.info-shell {
    display: flex;
}

.info-row {
    display: flex;
    align-items: center;
}

.title-info-pair {
    display: flex;
    align-items: center;
}

.ultra-strong {
    font-weight: 1000;
}

.nameLocation {
    border-bottom: 2px solid;
    width: 272px;
    padding: 1px 5px;
}

.playerLocation {
    border-bottom: 2px solid;
    width: 123px;
}

.raceLocation {
    border-bottom: 2px solid;
    width: 126px;
    padding: 1px 5px;
}

.levelLocation {
    border-bottom: 2px solid;
    width: 28px;
    text-align: center;
}

.primaryLocation {
    width: 100px;
    padding-left: 5px;
    border-bottom: 2px solid;
}

.secondaryLocation {
    width: 100px;
    border-bottom: 2px solid;
    padding-left: 5px;
}

.crpLocation {
    width: 53px;
    text-align: center;
    border-bottom: 2px solid;
}

.excurrentLocation {
    text-align: center;
    width: 53px;
    border-bottom: 2px solid;
}

.extolevelLocation {
    width: 53px;
    text-align: center;
    border-bottom: 2px solid;
}

.bonfireLogo {
    background-image: url(/static/media/logo.041f3164.svg);
    background-size: contain;
    background-repeat: no-repeat;
    width: 213px;
}
.statsShell {
    width: 127px;
}

.statRowsShell div {
    display: flex;
    justify-content: space-between;
}

.statRowsShell div:nth-child(even) {
    background: lightgray;
}

.statRowsShell p {
    z-index: 1;
    margin: 0;
    min-height: 19px;
}

.statRowsShell div div, .statRowsShell div p, .statRowsShell div input {
    width: 50%;
    text-align: center;
}

.statRowsShell p:nth-child(2) {
    font-family: 'Vollkorn SC', serif;
    font-weight: bold;
    margin-top: 2px
}

.movementShell {
    width: 292px;
}

.movementCategory {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.movementCategory:nth-child(even) {
    background: lightgray;
}

.movementCategory div:nth-child(even) {
    background: inherit;
}

.movementRowsSell p, .movementShell input {
    z-index: 1;
    margin: 0;
    min-height: 19px;
}

.movementCategory {
    position: relative;
}

.movementRowsSell p:first-child {
    font-family: 'Vollkorn SC', serif;
    margin-top: 2px
}

.label {
    padding-left: 5px;
    width: 50px;
}

.speed {
    width: 50px;
    text-align: center;
}

.lengthShell {
    font-family: 'Vollkorn SC', serif;
    margin-top: 2px;
    width: 173px;
}

.lengthShell p:first-child {
    margin-top: 0
}

.strikeThrough {
    position: absolute;
    width: 100%;
    height: 2px;
    background: #990000 !important;
}

.socialShell {
    margin-top: 10px;
}

.socialShell h2 {
    text-align: left;
    padding-left: 10px
}

.socialShell p, .socialShell input {
    z-index: 1;
    margin: 0;
}

.honorShell p {
    font-family: 'Vollkorn SC', serif;
    font-size: 11px;
}

.honorShell, .honorShell div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.honorShell input {
    width: 30px;
}

.honorCategoryShell {
    position: relative;
    border-left: 2px solid lightgray;
    border-right: 2px solid lightgray;
}

.honorCategoryShell p {
    height: 20px;
    padding: 0 1px;
}

.honorLocation {
    width: 48px;
    text-align: center;
}

.extrahonordiceLocation {
    width: 28px;
    text-align: center;
}

.socialBodyShell {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
}

.socialRightShell, .socialLeftShell {
    width: 224px;
}

.socialRightShell p {
    height: 19px;
}

.socialLeftShell {
    border-right: 1px solid #222;
}

.socialLeftShell h2 {
    margin-right: -1px
}

.temperamentLocation {
    width: 223px;
    background-color: lightgray;
    height: 19px;
}

div.temperamentLocation {
    width: 217px;
    display: flex;
    align-items: center;
}

div.temperamentLocation strong {
    margin: 0px 4px;
}

.reputationShell {
    display: flex;
}

.reputationTitles p, .reputationTitles input {
    font-family: 'Vollkorn SC', serif;
    height: 19px;
    padding-top: 1px;
}

.reputationTitles p:nth-child(even), .reputationTitles input:nth-child(even), .contactStriping div:nth-child(even), .honorCategoryShell p:nth-child(odd)  {
    background: lightgray;
}

.contactStriping div {
    height: 17.59px;
}

.contactstextArea {
    position: absolute !important;
    width: 435px;
    height: 102px;
    overflow: hidden;
}

.editPairRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.pairRowStriping, .contentPairListShell input, .contentListShell input, .editPairRow input {
    height: 19px;
    margin: 0;
}

.contentPairListShell input {
    text-align: center;
}

.contentPairListShell input:first-child {
    text-align: unset;
}

.gearColumn .contentPairListShell input:first-child {
    font-size: 10px;
}

.gearColumn .viewPairListStriping {
    border-left: 2px solid grey;
}

.gearColumn:first-child .viewPairListStriping {
    border-left: none;
}

.pairRow {
    height: 19px;
}

.contentPairListShell .pairRow:nth-child(even) {
    background: lightgray;
}

.skillBorder {
    border-left: 2px solid grey;
}

.viewPairListStriping {
    overflow: hidden;
}

.viewPairListStriping .stripesShell, .viewPairListStriping .contentPairListShell, .viewPairListStriping .contentListShell {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-wrap: wrap;
}

.titleInput {
    padding: 1px 4px;
    text-align: left;
}

.valueInput {
    padding: 0 4px;
    text-align: right;
}

.valueDisplay {
    text-align: right;
}

.skillInput {
    padding: 0 4px;
    width: 55%;
}

.costShell {
    padding: 0 4px;
    text-align: left;
    width: 25%;
    z-index: 1;
}

.costView {
    padding: 0 4px;
    text-align: center;
    width: 25%;
}

.rankInput {
    padding: 0 4px;
    text-align: center;
    width: 25%;
}

.modInput {
    padding: 0 4px;
    text-align: center;
    width: 25%;
}

.costShell {
    position: relative;
}

.costInput  {
    width: 100%;
}

#totalCost {
    padding: 0 4px;
    text-align: right;
    position: absolute;
    top: 4px;
    right: 1px;
    z-index: -1;
}

#combatTotalCost {
    position: absolute;
    padding: 0 4px;
    text-align: right;
    width: 40px;
    z-index: 0;
}
.stressThresholdShell p, .stressThresholdShell input {
    z-index: 1;
    margin: 0;
    min-height: 19px;
}

.stressThresholdShell {
    width: 159px;
}

.vitalShell {
    border: 2px solid black;
}

.vitalShell div {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.vitalShell div p, .vitalShell div input {
    margin: 0;
    width: 50%;
    height: unset;
    min-height: unset;
}

.vitalShell div p:first-child {
    font-family: 'Vollkorn SC', serif;
    font-size: 11px;
    padding-left: 5px;
}

.vitalShell div:first-child p:first-child {
    background: #222;
    color: whitesmoke;
    padding-top: 1px;
    font-size: 13px;
    padding-left: 5px;
    margin: -1px;
}

.favormaxLocation {
    top: 509px;
    left: 545px;
    width: 75px;
    text-align: center;
}

.currentfavorLocation {
    top: 489px;
    left: 545px;
    width: 75px;
    text-align: center;
}

.anointedDivShell {
    margin: auto;
}

.anointedDiv {
    padding: 1px;
    border: 1px solid grey;
    background: whitesmoke;
    width: 19px;
    height: 19px;
}

.checkboxShellInner {
    width: 50%;
    background: none !important;
    justify-content: space-around !important;
}

.dieAndMinShell {
    margin-top: 5px;
    border: solid 2px black;
}

.dieAndMinShell div {
    display: flex;
}

.dieAndMinShell div:nth-child(even) {
    background-color: lightgray;
}

.dieAndMinShell div p {
    width: 50%;
    text-align: center;
}

.dieAndMinShell div p:first-child {
    width: 75%;
    text-align: left;
    font-family: 'Vollkorn SC', serif;
    font-size: 11px;
    padding: 2px 0 0 5px;
}

.dieAndMinShell input {
    width: 50%;
    text-align: center;
}
.rangeShell {
    display: flex;
    flex-direction: column;
    width: 158px;
}

.rangeShell div {
    display: flex;
    justify-content: space-between;
}

.rangeShell div p {
    text-align: center;
}

.rangeShell div p:first-child, .rangeShell div p:nth-child(3) {
    font-family: 'Vollkorn SC', serif;
    font-size: 13px;
    font-weight: bold;
    width: 30px;
}

.rangeShell div:nth-child(odd) {
    background: lightgray;
}

.rangeShell p, .rangeShell input {
    z-index: 1;
    margin: 0;
    min-height: 19px;
}

.maxrangeLocation {
    width: 50px;
    text-align: center;
}
.vitalsShell {
    margin-top: 5px;
}

.stressShell, .editingStressThreshold {
    display: flex;
    justify-content: space-between;
}

.vitalsShell input {
    z-index: 1;
    margin: 0;
}

.stressShell div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 50%;
}

.editingStressThreshold div {
    display: flex;
    align-items: center;
}

.editingStressThreshold div {
    width: 100%;
}

.stressShell div p {
    width: 35%;
}

.stressShell div input {
    width: 65%;
}

.stressShell .relaxationLocation {
    width: 50%;
}

.stressShell div:first-child {
    border: 2px solid #990000;
    width: 49%;
}

.stressShell div p:first-child, .editingStressThreshold p {
    font-family: 'Vollkorn SC', serif;
    font-weight: bold;
    padding-top: 2px;
}

.stressShell div:last-child p:first-child {
    font-family: 'Vollkorn SC', serif;
    margin: 0;
    font-weight: normal;
    font-size: 10px;
    padding-left: 5px;
}

.stressShell div:last-child p:last-child {
    text-align: center;
}

.currentstressLocation {
    width: 74px;
    text-align: center;
    z-index: 1;
}

.stressthresholdLocation {
    width: 74px;
    text-align: center;
}

.mentalBreak {
    background-color: #990000;
    color: whitesmoke;
}

.relaxationLocation {
    width: 74px;
    text-align: center;
}

.woundCategoryShell {
    position: relative;
    display: flex;
}

.woundCategoryShell div {
    width: 25%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.woundCategoryShell div:nth-child(even) {
    background: lightgray;
}

.woundCategoryShell div p:first-child, .currentBox p:first-child, .thresholdBox p:first-child {
    font-family: 'Vollkorn SC', serif;
    font-weight: bold;
    padding-top: 2px;
}

.woundCategoryShell p:nth-child(2) {
    width: 60%;
    font-size: 10px;
}

.currentDamageRow {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.currentBox p:first-child, .thresholdBox p:first-child {
    font-size: 9px;
}

.currentBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border: 2px solid #990000;
    padding: 0 3px;
}

.downloadStylings .currentBox p:last-child {
    color: white !important
}

.sizemodLocation {
    top: 824px;
    width: 48px;
    left: 524px;
    text-align: center;
}

.thresholdBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border: 2px solid grey;
}

.tinyGrey {
    font-family: 'Vollkorn SC', serif;
    font-size: 10px !important;
    text-align: center;
    color: grey;
}

.damageShell {
    display: flex;
    justify-content: space-between;
}

.damageShellLeft, .damageShellRight {
    display: flex;
    justify-content: space-between;
}

.damageShellLeft {
    width: 46%;
}

.woundTitleShell p {
    font-family: 'Vollkorn SC', serif;
    margin: 0;
    color: grey;
    height: 19px;
}

.damageShellLeft .woundTitleShell p {
    width: 60px;
}

.damageShellRight .woundTitleShell p {
    width: 77px;
}

.woundTitleShell p:nth-child(even) {
    background: lightgray;
}

.criticalLocation {
    width: 60%;
    text-align: center;
}

.hurtLocation {
    width: 74px;
    text-align: center;
}

.bloodiedLocation {
    width: 74px;
    text-align: center;
}

.woundedLocation {
    width: 74px;
    text-align: center;
}

.currentDamageLocation {
    width: 51px;
    text-align: center;
}

.traumaLocation {
    width: 74px;
    text-align: center;
}

.vitalyRollsShell {
    width: 33%;
    display: flex;
    justify-content: space-between;
}

.vitalyRollsShell div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 2px;
    border-right: 2px solid lightgrey;
}

.vitalyRollsShell div:last-child {
    border-right: none;
}

.vitalyRollsShell div p:first-child {
    font-family: 'Vollkorn SC', serif;
    margin: 0;
    padding-top: 2px;
    font-size: 10px;
}

.vitalyRollsShell div p:nth-child(2), .vitalyRollsShell div input:nth-child(2) {
    width: 38%
}

.vitalityrollLocation {
    width: 47px;
    text-align: center;
}

.vitalitydiceLocation {
    width: 36px;
    text-align: center;
}

.vitalityminLocation {
    width: 36px;
    text-align: center;
}

.abilitiesShell {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.classShell {
    width: 66%;
}

.abilitiesContentShell {
    display: flex;
    justify-content: space-between;
}

.abilityShell {
    width: 49.5%;
    position: relative;
}

.abilities {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
}

.drawbacksShell {
    width: 33%;
}

.drawbackInnerShell {
    position: relative;
}

.removedAbilityShell {
    display: flex;
    justify-content: space-between;
}

.removedAbilityShell h2 {
    padding: 0 5px;
}

.removedabilityLocation {
    width: 125px;
    text-align: center;
}

/* .abilitiesShell {
    width: 776px;
    left: 20px;
}

.abilitiesShell .ability-header {
    display: flex;
}

.abilitiesShell .ability-header h1, .abilities {
    width: 33.33%;
}

.abilitiesShell .ability-header h1:first-child {
    width: 66.67%;
}

.abilitiesContentShell {
    display: flex;
    justify-content: space-between;
    position: relative;
}

.abilityShell .contactStriping div, .removedAbilityShell {
    height: 17.6px;
}

.abilitiestwotextArea {
    width: 33%;
    height: 165px;
    overflow: hidden;
}

.removedAbilityShell {
    display: flex;
    justify-content: space-between;
}

.removedAbilityShell h2 {
    padding: 0 5px;
}

.removedabilityLocation {
    width: 125px;
    text-align: center;
}

.abilitiesWidth {
    position: absolute;
    width: 33%;
} */
.skillOuterShell {
    margin-top: 5px;
}

.skillShell {
    display: flex;
    justify-content: space-between;
}

.skillLeftColumn {
    width: 33%;
}

.skillRightColumn {
    width: 67%
}

.skillMods {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.skillMods div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 33%;
    height: 19px;
}

.skillMods div:nth-child(odd) {
    background: lightgray
}

.skillMods div p:first-child, .skillAdept p:first-child, .skillRow p:first-child {
    font-family: 'Vollkorn SC', serif;
    font-weight: bold;
    margin-top: 2px;
    padding-left: 5px;
    text-align: left;
    width: 55%;
}

.skillMods div p:nth-child(2) {
    width: 24px;
    text-align: center;
}

.skillAdept {
    display: flex;
    justify-content: space-between;
    border-top: 2px solid lightgrey;
    height: 19px;
}

.skilladeptLocation {
    width: 120px;
    text-align: center;
}

.skillShell p, .skillShell input {
    z-index: 1;
    margin: 0;
    min-height: 19px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.skillsuiteShell {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.skillsuiteShell .skillRow {
    display: flex;
    justify-content: space-between;
    height: 19.5px;
    position: relative;
}

.skillsuiteShell .skillRow:nth-child(odd), .combatRow .skillRow:nth-child(odd) {
    background: lightgray
}

.skillsuiteShell .skillRow:first-child {
    background: #222;
}

.skillRow p, .skillRow input {
    text-align: center;
    width: 25%;
}

.skillsuiteShell .skillRow h2:first-child {
    width: 55%;
}

.skillRow h2:nth-child(2), .skillRow h2:nth-child(3), .skillRow h2:nth-child(4) {
    font-size: 10px;
    padding-top: 3px;
    padding-left: 0px;
    text-align: center;
    width: 25%;
}

.skillRowHeaderShell {
    display: flex;
}

.skillRowHeader {
    background-color: #222;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.skillRowHeader h2 {
    width: 30px;
}

.skillRowHeader h2:first-child {
    width: 55%;
    text-align: left;
    padding-left: 5px;
}

.navLang {
    width: 95px !important;
    font-size: 11px !important;
}
.cashAndGearShell p, .cashAndGearShell input {
    z-index: 1;
    margin: 0;
    height: 19px;
}

.cashAndGearShell {
    display: flex;
    flex-direction: column;
}

.cashTitle {
    display: flex;
}

.cashAndGearShell h1 {
    width: 464px;
}

.cashShell {
    display: flex;
    height: 22.4px;
    padding: 0px 2px;
}

.cashIndividual {
    width: 54px;
    text-align: center;
}

.cashShell div {
    width: 75px;
    display: flex;
    justify-content: space-between;
    padding: 0 2px
}

.cashShell div:nth-child(odd) {
    background: white
}

.cashShell div:nth-child(even) {
    background: lightgray
}

.cashShell div p {
    padding-top: 4px;
    font-family: 'Vollkorn SC', serif;
    font-weight: bold;
}
.combatshell {
    display: flex;
    margin-left: 5px;
}

.combatSkills {
    width: 225px;
    display: flex;
    flex-direction: column;
}

.combatMods {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.combatMods div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 25%;
    height: 19px;
}

.combatMods div:nth-child(even) {
    background: lightgray
}

.combatMods p:first-child {
    font-family: 'Vollkorn SC', serif;
    font-weight: bold;
    margin-top: 2px;
    padding-left: 5px;
}

.combatMods div p:nth-child(2) {
    width: 24px;
    text-align: center;
}

.combatAdept {
    display: flex;
    justify-content: space-between;
    border-top: 2px solid lightgrey;
    height: 19px;
    width: 100% !important;
}

.combatAdept p:first-child {
    font-family: 'Vollkorn SC', serif;
    font-weight: bold;
    margin-top: 2px;
    padding-left: 5px;
}

.combatAdept input:nth-child(2), .combatAdept p:nth-child(2) {
    width: 55px;
    text-align: center;
}

.skilladeptLocation {
    width: 111px !important;
    text-align: center;
}

.baseCombatStatsShell p, .statsShell input, .combatSkills p, .combatSkills input {
    z-index: 1;
    margin: 0;
    min-height: 19px;
}

.combatRow .skillRow {
    display: flex;
    height: 19px;
    position: relative;
}

.combatRow div h2 {
    text-align: left;
    padding-left: 10px;
    margin-right: -1px;
}

.combatRow .skillRow p, .combatRow .skillRow h2, .combatRow .skillRow input, .combatCost, .combatRank, .combatTotalCost {
    width: 25%;
}

.combatRow .skillRow p:first-child, .combatRow .skillRow h2:first-child, .combatInput {
    width: 55%;
}

.skillRow p:first-child {
    font-family: 'Vollkorn SC', serif;
    font-weight: bold;
    margin-top: 2px;
    padding-left: 5px;
    text-align: left;
    width: 55%;
}

.advSkillBackgroundShell div .skillRow {
    display: flex;
    justify-content: space-between;
}

.advSkillBackgroundShell div .skillRow:first-child {
    background: #222
}

.advSkillBackgroundShell div .skillRow h2:first-child, .advSkillBackgroundShell div .skillRow p:first-child {
    width: 151px;
    text-align: left;
    padding-left: 10px;
}

.advSkillBackgroundShell div .skillRow h2:nth-child(2), .advSkillBackgroundShell div .skillRow h2:nth-child(3),
.advSkillBackgroundShell div .skillRow h2:nth-child(4), .advSkillBackgroundShell div .skillRow p:nth-child(2),
.advSkillBackgroundShell div .skillRow p:nth-child(3), .advSkillBackgroundShell div .skillRow p:nth-child(4) {
    width: 40px;
}

.combatSkillRowShell .skillRow h2, .combatRanks, .combatCost, .combatTotalCost {
    width: 25% !important;
}

.combatSkillRowShell .skillRow h2:first-child, .combatInput {
    width: 55% !important;
}

.combatAdvancedSkill {
    width: 386px;
    height: 186px;
}

.skillRowHeader {
    background-color: #222;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.skillRowHeader h2 {
    width: 45px;
}

.skillRowHeaderShell .skillRowHeader h2 {
    width: 25%;
}

.skillRowHeader h2:first-child {
    width: 55%;
    text-align: left;
    padding-left: 5px;
}
.armorBlockShell {
    width: 151px;
    border: 2px #222 solid;
    display: flex;
    flex-direction: column;
}

.armorBlockShell p, .armorBlockShell input {
    z-index: 1;
    margin: 0;
    height: 19px;
}

.armorBlockShell div:nth-child(even) {
    background: lightgray;
}

.armorBlockShell div, .shieldBlockShell div {
    display: flex;
    justify-content: flex-start;
}

.armornameLocation {
    padding: 2px 2px 0;
    background: lightgrey;
    width: 100%;
    border-radius: 0;
    text-align: left;
    font-size: 12px;
    height: 19px;
}

.armorBonusArea {
    position: relative;
    width: 100%;
    height: 75px;
    overflow: hidden;
    padding: 0 2px;
}

.armorBonusAreaView {
    height: 48px;
}

.shieldBonusArea {
    height: 74px;
}

.armorBonusArea p:first-child {
    padding-top: 2px;
    font-family: 'Vollkorn SC', serif;
    font-weight: bold;
}

.armorBonusArea p:last-child, .armorBonusArea textarea:last-child {
    width: 100%;
    text-indent: 47px;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
}

.armorBonusArea textarea {
    overflow: hidden;
    text-indent: 40px;
}

.calculatedStatsShell .calculatedStats:nth-child(even) {
    background: whitesmoke;
}
.basicStats {
    padding: 0 2px;
}

.basicStats p:first-child {
    padding-top: 2px;
    font-family: 'Vollkorn SC', serif;
    font-weight: bold;
}

.basicStats p:last-child, .basicStats input:last-child {
    text-align: center;
}

.basicStats p:first-child, .basicStats p:last-child, .basicStats input:first-child, .basicStats input:last-child {
    width: 50%;
}

.calculatedStatsShell {
    display: flex;
    flex-direction: column;
}

.calculatedStats {
    display: flex;
    text-align: center;
}

.calculatedStatsHeading {
    background: grey !important;
    font-family: 'Vollkorn SC', serif;
    color: white
}

.calculatedStatsHeading p {
    padding-top: 2px;
}

.calculatedStats p, .calculatedStats input {
    width: 25px;
    text-align: center;
    border-right: 2px solid grey;
    margin: 0;
    height: 19px;
}

.calculatedStats.shield p, .calculatedStats.shield input, .calculatedStats.shield.calculatedStatsHeading p {
    width: 33px;
}

.calculatedStats p:last-child {
    padding: 0 0 0 2px;
    font-family: 'Vollkorn SC', serif;
    font-weight: bold;
    text-align: left;
    width: 35px;
    border-right: none;
}

.nameClass {
    height: 19px;
}
.shieldBlockShell p, .shieldBlockShell input {
    z-index: 1;
    margin: 0;
    height: 19px;
}

.shieldBlockShell {
    width: 151px;
    border: 2px #222 solid;
    display: flex;
    flex-direction: column;
}

.shieldnameLocation {
    padding: 4px 2px 0;
    background: lightgrey;
    width: 100%;
    border-radius: 0;
    text-align: left;
    font-size: 12px;
    width: 100%;
    height: 19px;
}

.twinShieldStats div {
    padding: 0;
    width: 50%;
}

.twinShieldStats div p, .twinShieldStats div input {
    width: 50%;
    text-align: center;
}

.twinShieldStats div p:first-child, .twinShieldStats div input:first-child {
    padding-top: 2px;
    font-family: 'Vollkorn SC', serif;
    font-weight: bold;
    text-align: left;
}

.calculatedStatsShellShield {
    display: flex;
    flex-direction: column;
}

.calculatedStatsShellShield .calculatedStats:nth-child(even), .shieldBlockShell>div:nth-child(even) {
    background: lightgray;
}
.weaponProfileShell {
    width: 154px;
    border-bottom: 2px #222 solid;
    border-right: 2px #222 solid;
    display: flex;
    flex-direction: column;
}

.weaponnameLocation {
    width: 154px;
}

.weaponnamefour {
    width: 173px
}

.weaponBonusArea {
    position: relative;
    width: 100%;
    height: 73px;
    overflow: hidden;
}

.weaponBonusArea textarea {
    height: 100%;
    overflow: hidden;
    text-indent: 40px;
    width: 100%;
}

.weaponTraitArea {
    position: relative;
    width: 100%;
    height: 64px;
    overflow: hidden;
}

.weaponTraitAreaFour {
    height: 84px;
}

.weaponBonusArea p:first-child, .weaponTraitArea p:first-child {
    padding-top: 2px;
    font-family: 'Vollkorn SC', serif;
    font-weight: bold;
    position: absolute;
}

.weaponBonusArea p:last-child, .weaponTraitArea p:last-child {
    width: 100%;
    text-indent: 42px;
    position: absolute;
}

.weaponTraitArea textarea {
    text-indent: 40px;
}

.bonusfour {
    height: 82px;
}

.armorBaseStats, .armorBaseStats div {
    display: flex;
    justify-content: space-between;
    height: 19px;
}

.basedamageLocation {
    width: 100px;
}

.traitsLocation {
    width: 149px;
    text-indent: 46px;
}

.traitstextArea {
    width: 149px;
    height: 42px;
    overflow: hidden;
}

textarea.traitsLocation {
    height: 54px;
    overflow: hidden;
}

.traitsfour {
    width: 173px;
    height: 60px;
}

.weaponCalculatedStats {
    display: flex;
    justify-content: space-between;
    text-align: center;
}

.weaponCalculatedStatsHeader {
    background: grey !important;
    font-family: 'Vollkorn SC', serif;
    color: white;
}

.weaponCalculatedStatsHeader p {
    font-size: 10px;
    padding-top: 3px;
}

.weaponCalculatedStats p, .weaponCalculatedStats input {
    width: 28px;
    text-align: center;
    border-right: 2px solid grey;
}

.weaponCalculatedStats p:last-child {
    padding-top: 2px;
    font-family: 'Vollkorn SC', serif;
    font-weight: bold;
    text-align: left;
    border-right: none;
    width: 37px;
}

.weaponPair {
    display: flex;
    justify-content: space-between;
}

.weaponPair div {
    width: 50%;
}

.armorShieldWeaponArea {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
}

.armorBaseStats div p:first-child, .armorBaseStats p:first-child {
    padding-top: 2px;
    font-family: 'Vollkorn SC', serif;
    font-weight: bold;
}

.armorBaseStats div p:last-child, .armorBaseStats p:last-child, .armorBaseStats div input:last-child, .armorBaseStats input:last-child {
    text-align: center;
    width: 50%;
}

.weaponProfileShell > div:nth-child(even) {
    background: lightgray;
}

.calculatedStatsShell.ranged div:nth-child(even) {
    background: whitesmoke;
}

.calculatedStatsShell.ranged div:nth-child(odd) {
    background: lightgray;
}
.headerShell {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    align-items: center;
    background-color: #2192b8;
    position: relative;
    color: whitesmoke;
    box-shadow: 5px 5px 15px 5px #000000;
}

.fa-home {
    font-size: 30px;
    color: whitesmoke
}

.fa-home:hover {
    color: lightgray;
    cursor: pointer;
}
.footerShell {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    align-items: center;
    background-color: #2192b8;
    position: relative;
    color: whitesmoke;
    box-shadow: 5px 5px 15px 5px #000000;
}

a:hover {
    color: lightgrey
}
h4 { 
    background: grey;
    padding: 10px 30px 10px 66px;
    color: whitesmoke;
    position: relative;
    margin: -1px 0;
}

.noCharacters {
    padding: 15px 64px 0;
    text-align: left;
}

.fa-users, .fa-cogs {
    position: absolute;
    color: white;
    font-size: 62px;
    left: -27px;
    top: -4px;
}

.homeShell {
    margin: 30px auto;
    max-width: 816px;
}

.homeShell .characterShell:first-child {
    margin-bottom: 15px;
}

.loginShell {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding-top: 30px;
}

.loginShell button {
    margin-top: 15px;
}

.characterShell {
    padding-bottom: 15px;
    background-color: white;
    box-shadow: 5px 5px 15px 5px #000000;
    overflow: hidden;
}

.character {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.characterLink {
    display: flex;
    width: 100%;
    justify-content: space-around;
}
.character:nth-child(even) {
    background: lightgray
}

.characterLink:hover {
    background: #2192b8;
    cursor: pointer;
    transition: .6s;
}
.characterLink:hover p, .characterLink:hover .fa-minus {
    color:whitesmoke;
    transition: .6s;
}

.character p {
    width: 25%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.character p:hover span, .character p:focus span {
    display: inline-block;
    animation-name: scroll-text;
    animation-duration: 7s;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: normal;
}

@keyframes scroll-text {
    0% {
      transform: translateX(0%);
    }
    90% {
      transform: translateX(-100%);
    }
    95% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(0%);
    }
  }

.character p:first-child {
    font-weight: 700;
    text-align: left;
    font-size: 13px;
    width: 50%;
    margin-left: 15px;
}

.character p:nth-child(2) {
    width: 20%;
}

.character p:nth-child(4) {
    width: 15%;
}

.character p:last-child {
    padding-right: 15px;
    line-height: 1.3;
}

.characterListButtonShell {
    display: flex;
    justify-content: space-between;
    margin: 0 35px;
}

.fa-plus, .fa-redo-alt {
    padding: 5px;
    border-radius: 15px;
}

.fa-redo-alt {
    margin: 15px 0 0
}

.fa-plus:hover, .fa-redo-alt:hover {
    background: #2192b8;
    transition: .6s;
    color: whitesmoke;
}

.greyed-out {
    color: grey
}


.greyed-out:hover {
    color: grey;
    background: transparent;
}

.fa-minus {
    padding: 5px;
    border-radius: 15px;
    margin-right: 15px;
}
.fa-minus:hover {
    color: #2192b8 !important;
    background: whitesmoke;
    transition: .6s;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #00000075;
}

.displayNone {
    display: none;
}

.modelBody {
    width: 422px;
    padding: 0px 0px 30px;
    background: white;
    margin: 125px auto;
    box-shadow: 5px 5px 15px 5px #000000;
}

.modelInnards {
    padding: 0 30px;
}

.modelBody p {
    margin: 0;
    text-align: left;
    text-indent: 23px;
}

.modelBody h5 {
    margin: 0;
    font-weight: 700;
    margin-bottom: 15px;
    text-align: center;
    background-color: #990000;
    color: whitesmoke;
    padding: 15px 20px;
}
.fa-exclamation-triangle {
    margin-right: 5px;
}

.modelButtons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
}

button {
    border: none;
    background: lightgray;
    border-radius: 15px;
    padding: 5px 10px;
    cursor: pointer;
}

button:hover {
    background: #2192b8;
    color: whitesmoke;
}

.reverseColors {
    background: #2192b8;
    color: whitesmoke;
}

.reverseColors:hover {
    background: lightgray;
    color: black
}

.reverseColors:hover a {
    color: black
}

.modelButtons .no:hover {
    background: #990000;
    color: whitesmoke;
}
