h4 { 
    background: grey;
    padding: 10px 30px 10px 66px;
    color: whitesmoke;
    position: relative;
    margin: -1px 0;
}

.noCharacters {
    padding: 15px 64px 0;
    text-align: left;
}

.fa-users, .fa-cogs {
    position: absolute;
    color: white;
    font-size: 62px;
    left: -27px;
    top: -4px;
}

.homeShell {
    margin: 30px auto;
    max-width: 816px;
}

.homeShell .characterShell:first-child {
    margin-bottom: 15px;
}

.loginShell {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding-top: 30px;
}

.loginShell button {
    margin-top: 15px;
}

.characterShell {
    padding-bottom: 15px;
    background-color: white;
    -webkit-box-shadow: 5px 5px 15px 5px #000000;
    box-shadow: 5px 5px 15px 5px #000000;
    overflow: hidden;
}

.character {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.characterLink {
    display: flex;
    width: 100%;
    justify-content: space-around;
}
.character:nth-child(even) {
    background: lightgray
}

.characterLink:hover {
    background: #2192b8;
    cursor: pointer;
    transition: .6s;
}
.characterLink:hover p, .characterLink:hover .fa-minus {
    color:whitesmoke;
    transition: .6s;
}

.character p {
    width: 25%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.character p:hover span, .character p:focus span {
    display: inline-block;
    animation-name: scroll-text;
    animation-duration: 7s;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: normal;
}

@keyframes scroll-text {
    0% {
      transform: translateX(0%);
    }
    90% {
      transform: translateX(-100%);
    }
    95% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(0%);
    }
  }

.character p:first-child {
    font-weight: 700;
    text-align: left;
    font-size: 13px;
    width: 50%;
    margin-left: 15px;
}

.character p:nth-child(2) {
    width: 20%;
}

.character p:nth-child(4) {
    width: 15%;
}

.character p:last-child {
    padding-right: 15px;
    line-height: 1.3;
}

.characterListButtonShell {
    display: flex;
    justify-content: space-between;
    margin: 0 35px;
}

.fa-plus, .fa-redo-alt {
    padding: 5px;
    border-radius: 15px;
}

.fa-redo-alt {
    margin: 15px 0 0
}

.fa-plus:hover, .fa-redo-alt:hover {
    background: #2192b8;
    transition: .6s;
    color: whitesmoke;
}

.greyed-out {
    color: grey
}


.greyed-out:hover {
    color: grey;
    background: transparent;
}

.fa-minus {
    padding: 5px;
    border-radius: 15px;
    margin-right: 15px;
}
.fa-minus:hover {
    color: #2192b8 !important;
    background: whitesmoke;
    transition: .6s;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #00000075;
}

.displayNone {
    display: none;
}

.modelBody {
    width: 422px;
    padding: 0px 0px 30px;
    background: white;
    margin: 125px auto;
    -webkit-box-shadow: 5px 5px 15px 5px #000000;
    box-shadow: 5px 5px 15px 5px #000000;
}

.modelInnards {
    padding: 0 30px;
}

.modelBody p {
    margin: 0;
    text-align: left;
    text-indent: 23px;
}

.modelBody h5 {
    margin: 0;
    font-weight: 700;
    margin-bottom: 15px;
    text-align: center;
    background-color: #990000;
    color: whitesmoke;
    padding: 15px 20px;
}
.fa-exclamation-triangle {
    margin-right: 5px;
}

.modelButtons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
}

button {
    border: none;
    background: lightgray;
    border-radius: 15px;
    padding: 5px 10px;
    cursor: pointer;
}

button:hover {
    background: #2192b8;
    color: whitesmoke;
}

.reverseColors {
    background: #2192b8;
    color: whitesmoke;
}

.reverseColors:hover {
    background: lightgray;
    color: black
}

.reverseColors:hover a {
    color: black
}

.modelButtons .no:hover {
    background: #990000;
    color: whitesmoke;
}