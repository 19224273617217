.socialShell {
    margin-top: 10px;
}

.socialShell h2 {
    text-align: left;
    padding-left: 10px
}

.socialShell p, .socialShell input {
    z-index: 1;
    margin: 0;
}

.honorShell p {
    font-family: 'Vollkorn SC', serif;
    font-size: 11px;
}

.honorShell, .honorShell div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.honorShell input {
    width: 30px;
}

.honorCategoryShell {
    position: relative;
    border-left: 2px solid lightgray;
    border-right: 2px solid lightgray;
}

.honorCategoryShell p {
    height: 20px;
    padding: 0 1px;
}

.honorLocation {
    width: 48px;
    text-align: center;
}

.extrahonordiceLocation {
    width: 28px;
    text-align: center;
}

.socialBodyShell {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
}

.socialRightShell, .socialLeftShell {
    width: 224px;
}

.socialRightShell p {
    height: 19px;
}

.socialLeftShell {
    border-right: 1px solid #222;
}

.socialLeftShell h2 {
    margin-right: -1px
}

.temperamentLocation {
    width: 223px;
    background-color: lightgray;
    height: 19px;
}

div.temperamentLocation {
    width: 217px;
    display: flex;
    align-items: center;
}

div.temperamentLocation strong {
    margin: 0px 4px;
}

.reputationShell {
    display: flex;
}

.reputationTitles p, .reputationTitles input {
    font-family: 'Vollkorn SC', serif;
    height: 19px;
    padding-top: 1px;
}

.reputationTitles p:nth-child(even), .reputationTitles input:nth-child(even), .contactStriping div:nth-child(even), .honorCategoryShell p:nth-child(odd)  {
    background: lightgray;
}

.contactStriping div {
    height: 17.59px;
}

.contactstextArea {
    position: absolute !important;
    width: 435px;
    height: 102px;
    overflow: hidden;
}
