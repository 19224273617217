.basicStats {
    padding: 0 2px;
}

.basicStats p:first-child {
    padding-top: 2px;
    font-family: 'Vollkorn SC', serif;
    font-weight: bold;
}

.basicStats p:last-child, .basicStats input:last-child {
    text-align: center;
}

.basicStats p:first-child, .basicStats p:last-child, .basicStats input:first-child, .basicStats input:last-child {
    width: 50%;
}

.calculatedStatsShell {
    display: flex;
    flex-direction: column;
}

.calculatedStats {
    display: flex;
    text-align: center;
}

.calculatedStatsHeading {
    background: grey !important;
    font-family: 'Vollkorn SC', serif;
    color: white
}

.calculatedStatsHeading p {
    padding-top: 2px;
}

.calculatedStats p, .calculatedStats input {
    width: 25px;
    text-align: center;
    border-right: 2px solid grey;
    margin: 0;
    height: 19px;
}

.calculatedStats.shield p, .calculatedStats.shield input, .calculatedStats.shield.calculatedStatsHeading p {
    width: 33px;
}

.calculatedStats p:last-child {
    padding: 0 0 0 2px;
    font-family: 'Vollkorn SC', serif;
    font-weight: bold;
    text-align: left;
    width: 35px;
    border-right: none;
}

.nameClass {
    height: 19px;
}