.editPairRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.pairRowStriping, .contentPairListShell input, .contentListShell input, .editPairRow input {
    height: 19px;
    margin: 0;
}

.contentPairListShell input {
    text-align: center;
}

.contentPairListShell input:first-child {
    text-align: unset;
}

.gearColumn .contentPairListShell input:first-child {
    font-size: 10px;
}

.gearColumn .viewPairListStriping {
    border-left: 2px solid grey;
}

.gearColumn:first-child .viewPairListStriping {
    border-left: none;
}

.pairRow {
    height: 19px;
}

.contentPairListShell .pairRow:nth-child(even) {
    background: lightgray;
}

.skillBorder {
    border-left: 2px solid grey;
}

.viewPairListStriping {
    overflow: hidden;
}

.viewPairListStriping .stripesShell, .viewPairListStriping .contentPairListShell, .viewPairListStriping .contentListShell {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-wrap: wrap;
}

.titleInput {
    padding: 1px 4px;
    text-align: left;
}

.valueInput {
    padding: 0 4px;
    text-align: right;
}

.valueDisplay {
    text-align: right;
}

.skillInput {
    padding: 0 4px;
    width: 55%;
}

.costShell {
    padding: 0 4px;
    text-align: left;
    width: 25%;
    z-index: 1;
}

.costView {
    padding: 0 4px;
    text-align: center;
    width: 25%;
}

.rankInput {
    padding: 0 4px;
    text-align: center;
    width: 25%;
}

.modInput {
    padding: 0 4px;
    text-align: center;
    width: 25%;
}

.costShell {
    position: relative;
}

.costInput  {
    width: 100%;
}

#totalCost {
    padding: 0 4px;
    text-align: right;
    position: absolute;
    top: 4px;
    right: 1px;
    z-index: -1;
}

#combatTotalCost {
    position: absolute;
    padding: 0 4px;
    text-align: right;
    width: 40px;
    z-index: 0;
}