.rangeShell {
    display: flex;
    flex-direction: column;
    width: 158px;
}

.rangeShell div {
    display: flex;
    justify-content: space-between;
}

.rangeShell div p {
    text-align: center;
}

.rangeShell div p:first-child, .rangeShell div p:nth-child(3) {
    font-family: 'Vollkorn SC', serif;
    font-size: 13px;
    font-weight: bold;
    width: 30px;
}

.rangeShell div:nth-child(odd) {
    background: lightgray;
}

.rangeShell p, .rangeShell input {
    z-index: 1;
    margin: 0;
    min-height: 19px;
}

.maxrangeLocation {
    width: 50px;
    text-align: center;
}