.headerShell {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    align-items: center;
    background-color: #2192b8;
    position: relative;
    color: whitesmoke;    
    -webkit-box-shadow: 5px 5px 15px 5px #000000;
    box-shadow: 5px 5px 15px 5px #000000;
}

.fa-home {
    font-size: 30px;
    color: whitesmoke
}

.fa-home:hover {
    color: lightgray;
    cursor: pointer;
}