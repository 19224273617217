@font-face {
  font-family: 'ancient';
  src: url('./assets/jancient.ttf') format("truetype");
}
@font-face {
  font-family: 'hamlet';
  src: url('./assets/Hamletornot.ttf') format("truetype");
}
@import url('https://fonts.googleapis.com/css2?family=Vollkorn+SC&display=swap');

body {
  margin: 0;
  background-color: gray;
  background-image: url(./assets/background.png);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  display: flex;
  align-items: center;
}

h1, h2, h3, h4, h5 {
  font-family: "hamlet";
  letter-spacing: 1px;
}

h1 {
  font-size: 25px;
}

a:hover h1, a:hover p {
  color: lightgray;
}

.beta {
  margin-left: 10px
}

.displayNone {
  display: none;
}