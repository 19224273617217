.weaponProfileShell {
    width: 154px;
    border-bottom: 2px #222 solid;
    border-right: 2px #222 solid;
    display: flex;
    flex-direction: column;
}

.weaponnameLocation {
    width: 154px;
}

.weaponnamefour {
    width: 173px
}

.weaponBonusArea {
    position: relative;
    width: 100%;
    height: 73px;
    overflow: hidden;
}

.weaponBonusArea textarea {
    height: 100%;
    overflow: hidden;
    text-indent: 40px;
    width: 100%;
}

.weaponTraitArea {
    position: relative;
    width: 100%;
    height: 64px;
    overflow: hidden;
}

.weaponTraitAreaFour {
    height: 84px;
}

.weaponBonusArea p:first-child, .weaponTraitArea p:first-child {
    padding-top: 2px;
    font-family: 'Vollkorn SC', serif;
    font-weight: bold;
    position: absolute;
}

.weaponBonusArea p:last-child, .weaponTraitArea p:last-child {
    width: 100%;
    text-indent: 42px;
    position: absolute;
}

.weaponTraitArea textarea {
    text-indent: 40px;
}

.bonusfour {
    height: 82px;
}

.armorBaseStats, .armorBaseStats div {
    display: flex;
    justify-content: space-between;
    height: 19px;
}

.basedamageLocation {
    width: 100px;
}

.traitsLocation {
    width: 149px;
    text-indent: 46px;
}

.traitstextArea {
    width: 149px;
    height: 42px;
    overflow: hidden;
}

textarea.traitsLocation {
    height: 54px;
    overflow: hidden;
}

.traitsfour {
    width: 173px;
    height: 60px;
}

.weaponCalculatedStats {
    display: flex;
    justify-content: space-between;
    text-align: center;
}

.weaponCalculatedStatsHeader {
    background: grey !important;
    font-family: 'Vollkorn SC', serif;
    color: white;
}

.weaponCalculatedStatsHeader p {
    font-size: 10px;
    padding-top: 3px;
}

.weaponCalculatedStats p, .weaponCalculatedStats input {
    width: 28px;
    text-align: center;
    border-right: 2px solid grey;
}

.weaponCalculatedStats p:last-child {
    padding-top: 2px;
    font-family: 'Vollkorn SC', serif;
    font-weight: bold;
    text-align: left;
    border-right: none;
    width: 37px;
}

.weaponPair {
    display: flex;
    justify-content: space-between;
}

.weaponPair div {
    width: 50%;
}

.armorShieldWeaponArea {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
}

.armorBaseStats div p:first-child, .armorBaseStats p:first-child {
    padding-top: 2px;
    font-family: 'Vollkorn SC', serif;
    font-weight: bold;
}

.armorBaseStats div p:last-child, .armorBaseStats p:last-child, .armorBaseStats div input:last-child, .armorBaseStats input:last-child {
    text-align: center;
    width: 50%;
}

.weaponProfileShell > div:nth-child(even) {
    background: lightgray;
}

.calculatedStatsShell.ranged div:nth-child(even) {
    background: whitesmoke;
}

.calculatedStatsShell.ranged div:nth-child(odd) {
    background: lightgray;
}