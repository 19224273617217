.armorBlockShell {
    width: 151px;
    border: 2px #222 solid;
    display: flex;
    flex-direction: column;
}

.armorBlockShell p, .armorBlockShell input {
    z-index: 1;
    margin: 0;
    height: 19px;
}

.armorBlockShell div:nth-child(even) {
    background: lightgray;
}

.armorBlockShell div, .shieldBlockShell div {
    display: flex;
    justify-content: flex-start;
}

.armornameLocation {
    padding: 2px 2px 0;
    background: lightgrey;
    width: 100%;
    border-radius: 0;
    text-align: left;
    font-size: 12px;
    height: 19px;
}

.armorBonusArea {
    position: relative;
    width: 100%;
    height: 75px;
    overflow: hidden;
    padding: 0 2px;
}

.armorBonusAreaView {
    height: 48px;
}

.shieldBonusArea {
    height: 74px;
}

.armorBonusArea p:first-child {
    padding-top: 2px;
    font-family: 'Vollkorn SC', serif;
    font-weight: bold;
}

.armorBonusArea p:last-child, .armorBonusArea textarea:last-child {
    width: 100%;
    text-indent: 47px;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
}

.armorBonusArea textarea {
    overflow: hidden;
    text-indent: 40px;
}

.calculatedStatsShell .calculatedStats:nth-child(even) {
    background: whitesmoke;
}