.movementShell {
    width: 292px;
}

.movementCategory {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.movementCategory:nth-child(even) {
    background: lightgray;
}

.movementCategory div:nth-child(even) {
    background: inherit;
}

.movementRowsSell p, .movementShell input {
    z-index: 1;
    margin: 0;
    min-height: 19px;
}

.movementCategory {
    position: relative;
}

.movementRowsSell p:first-child {
    font-family: 'Vollkorn SC', serif;
    margin-top: 2px
}

.label {
    padding-left: 5px;
    width: 50px;
}

.speed {
    width: 50px;
    text-align: center;
}

.lengthShell {
    font-family: 'Vollkorn SC', serif;
    margin-top: 2px;
    width: 173px;
}

.lengthShell p:first-child {
    margin-top: 0
}

.strikeThrough {
    position: absolute;
    width: 100%;
    height: 2px;
    background: #990000 !important;
}
